import React from 'react';

interface Props {
  active?: boolean;
}

function BriefCase({ active }: Props) {
  return (
    <svg
      style={{ width: '18px', height: '18px' }}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="industry"
      className="svg-inline--fa fa-briefcase fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={active ? '#00cf34' : '#999'}
        d="M320 336c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-48H0v144c0 25.6 22.4 48 48 48h416c25.6 0 48-22.4 48-48V288H320v48zm144-208h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128v32z"
      ></path>
    </svg>
  );
}

export default BriefCase;
