import React, { type ThHTMLAttributes } from 'react';

import ChevronUp from 'js/icons/ChevronUp';
import ChevronDown from 'js/icons/ChevronDown';
import { type ProjectsSortKeys } from 'js/api/projects';

import { toClassNames } from 'js/utils';

import { type Columns, type ColumnData, type SortProps } from './types';

interface HeadingRowProps extends SortProps<ProjectsSortKeys> {
  columns: Columns;
}

export default function HeadingRow({
  columns,
  onSort,
  sortBy,
  sortDir,
}: HeadingRowProps) {
  // if none of the header cells have 'width' defined, apply even spacing (pct)
  // when only some cells have 'width' specified, the others will fill the remaining space
  const isDefaultWidth = !Object.values(columns).some(
    (colData) => colData.width
  );
  const widthProp = isDefaultWidth
    ? { width: `${100 / Object.keys(columns).length}%` }
    : {};

  const headingCells = Object.keys(columns).map((key) => {
    const { sortable, ...cellProps } = columns[key];
    const active = sortBy === key;
    const newSortDir = active ? sortDir : null;
    const sortProps = sortable
      ? { onSort, sortDir: newSortDir, sortBy: key as ProjectsSortKeys }
      : {};

    const props = { ...cellProps, ...sortProps, ...widthProp, active };

    return <HeadingCell key={key} {...props} />;
  });

  return <tr>{headingCells}</tr>;
}

interface HeadingCellProps extends ColumnData, SortProps<ProjectsSortKeys> {
  active?: boolean;
}

function HeadingCell({
  active,
  label = null,
  onSort,
  sortDir,
  sortBy,
  width,
}: HeadingCellProps) {
  const style = {
    ...styles.headingCell,
    ...(width ? { width } : {}),
    ...(onSort ? styles.headingCellSortable : {}),
  };

  const cellProps: ThHTMLAttributes<HTMLTableCellElement> = { style };
  let sortChevron = <span className="chevron-placeholder" />;

  if (onSort) {
    const nextSortDir = sortDir ? (sortDir === 'asc' ? 'desc' : 'asc') : 'asc';
    cellProps.onClick = () => onSort && sortBy && onSort(sortBy, nextSortDir);
    cellProps.className = toClassNames('sortable', active && 'active');

    if (sortDir === 'asc') sortChevron = <ChevronUp />;
    if (sortDir === 'desc') sortChevron = <ChevronDown />;
  }

  return (
    <th {...cellProps}>
      <div className="header-filter-cell">
        <div className="header-cell-label">{label}</div>
        <div className="chevron-icon">{sortChevron}</div>
      </div>
    </th>
  );
}

const styles = {
  headingCell: {
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  headingCellSortable: {
    cursor: 'pointer',
  },
} as const;
