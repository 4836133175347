import styled, { css } from 'styled-components';
import { Grid } from 'js/components/partial/FlexboxGrid';

import Button, {
  type Props as ButtonProps,
} from 'js/components/partial/Button';
import { Input } from 'js/components/partial/Forms';

export const StatusFiltersLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

interface WithMarginProps {
  withMargin?: boolean;
}

export const AddProjectButton = styled(Button)<WithMarginProps & ButtonProps>`
  float: right;
  padding: 8px;
  font-size: 12px;
  height: 34px;
  width: 134px;

  ${(props) =>
    props.withMargin
      ? css`
          margin-right: 20px;
        `
      : ''};
`;

export const GridStyled = styled(Grid)`
  position: relative;
`;

export const SearchInput = styled(Input).attrs({
  type: 'search',
  placeholder: 'Search by name, address, designer, or salesperson',
})`
  height: 34px;
  width: 385px;
`;

export const ProjectsFilterContainer = styled.div`
  margin: 70px auto 20px auto;
`;

export const SearchContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

export const SpinnerStatusWrapper = styled.div`
  .icon-spinner-circle {
    position: absolute !important;
    right: 20px !important;
    left: auto !important;
    top: 5px !important;
    width: 20px;
    height: 20px;
  }
`;
