import React, { type ReactNode } from 'react';

import IconEmpty from 'js/icons/Empty';

interface Props {
  subTitle?: ReactNode;
}

function NoProjects({ subTitle }: Props) {
  return (
    <div className="no-projects">
      <IconEmpty />
      <h4>No projects found</h4>
      {subTitle}
    </div>
  );
}

export default NoProjects;
