import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

function ChevronUp({ color = '#999', ...props }: Props) {
  return (
    <svg
      className="icon icon-chevron-up"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      {...props}
    >
      <path
        className="color"
        fill={color}
        d="M5,4.6l3,3c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L5.7,2.5C5.5,2.3,5.3,2.2,5,2.2c-0.3,0-0.5,0.1-0.7,0.3L0.6,6.1c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0L5,4.6L5,4.6z"
      />
    </svg>
  );
}

export default ChevronUp;
