import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';

import { toClassNameObj } from 'js/utils';

import { FILTER_STATUS, type ProjectsFacets } from 'js/api/projects';

const StatusFiltersLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

interface Props {
  facets: Partial<ProjectsFacets>;
  activeFilter: FILTER_STATUS;
  statusFiltersList: FILTER_STATUS[];
}

export function ProjectStatusFilters({
  activeFilter,
  facets,
  statusFiltersList,
}: Props) {
  return (
    <StatusFiltersLinks className="status-filters">
      {statusFiltersList.map((status) => (
        <Link
          key={status}
          {...toClassNameObj(status === activeFilter && 'active')}
          href={`/eames/projects/?status=${status}`}
          as={`/projects/${status}/`}
        >
          {`${status.toUpperCase()} (${facets[status] ?? 0})`}
        </Link>
      ))}
    </StatusFiltersLinks>
  );
}
