import React from 'react';

import {
  FILTER_STATUS,
  GetProjectsInfiniteQuery,
  type ProjectsSortKeys,
  type ProjectsSortParams,
  type GetProjectsInfiniteQueryRenderFn,
  type Project,
} from 'js/api/projects';

import SpinnerCircle from 'js/icons/SpinnerCircle';

import HeadingRow from './HeadingRow';
import { ProjectRow } from './ProjectRow';
import { TABLE } from './constants';
import { MoreButtonStyled, SpinnerContainer } from './styled';
import { type SortHandler } from 'js/api';

// @TODO value was 15 revert it after full test pagination and filtration
const ITEMS_PER_PAGE = 5;

interface Props {
  status: FILTER_STATUS;
  keyword: string;
  projectType: Project['projectType'];
}

interface State {
  isVisible: boolean;
  sort?: ProjectsSortParams;
}

class TableSection extends React.Component<Props, State> {
  override state: State = {
    isVisible: false,
  };

  private observer: IntersectionObserver | null = null;

  private sectionRef = React.createRef<HTMLElement>();

  override componentDidMount() {
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.setState({ isVisible: true });
        this.observer?.disconnect();
      }
    });

    if (this.sectionRef.current) {
      this.observer.observe(this.sectionRef.current);
    }
  }

  override componentWillUnmount() {
    this.observer?.disconnect();
  }

  /** Handlers */

  private handleSort: SortHandler<ProjectsSortKeys> = (sortBy, sortDir) => {
    this.setState({ sort: { sortBy, sortDir } });
  };

  /** Render */

  private renderProjectsTable: GetProjectsInfiniteQueryRenderFn = (result) => {
    const { status } = this.props;
    const { sort } = this.state;
    const columns = TABLE[status].COLUMNS;

    const projectsPages = result.data?.pages;

    return (
      <div>
        <div className="responsive-table" style={styles.container}>
          <div className="responsive-table-wrapper" style={styles.tableWrapper}>
            <table style={styles.table}>
              <thead>
                <HeadingRow
                  columns={columns}
                  onSort={this.handleSort}
                  sortBy={sort?.sortBy}
                  sortDir={sort?.sortDir}
                />
              </thead>
              <tbody>
                {projectsPages?.map((page) =>
                  page.data?.map((project) => (
                    <ProjectRow
                      key={`${status}-${project.id}`}
                      project={project}
                      columns={columns}
                    />
                  ))
                )}
              </tbody>
            </table>
            {result.isLoading && (
              <SpinnerContainer>
                <SpinnerCircle />
              </SpinnerContainer>
            )}
          </div>
        </div>

        {result.hasNextPage && (
          <MoreButtonStyled
            onClick={() => result.fetchNextPage()}
            disabled={result.isLoading}
            block
          >
            View more projects
          </MoreButtonStyled>
        )}
      </div>
    );
  };

  private renderSection: GetProjectsInfiniteQueryRenderFn = (result) => {
    const { status } = this.props;

    const projectsIsEmpty = result.data?.pages?.[0].data.length === 0;

    return (
      <section ref={this.sectionRef} className="table-section">
        <div className="title">
          <h3>{TABLE[status].TITLE}</h3>
        </div>
        {projectsIsEmpty ? (
          <div style={styles.noProjectsWrapper}>No projects</div>
        ) : (
          this.renderProjectsTable(result)
        )}
      </section>
    );
  };

  override render() {
    const { status, keyword, projectType } = this.props;
    const { sort, isVisible } = this.state;

    return (
      <GetProjectsInfiniteQuery
        options={{ enabled: isVisible, keepPreviousData: true }}
        filters={{
          sort,
          status,
          page: 1,
          perPage: ITEMS_PER_PAGE,
          q: keyword || undefined,
          projectType: projectType || undefined,
        }}
      >
        {this.renderSection}
      </GetProjectsInfiniteQuery>
    );
  }
}

const styles = {
  container: {
    boxSizing: 'border-box',
    width: '100%',
  },
  tableWrapper: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  noProjectsWrapper: {
    width: '100%',
    textAlign: 'center',
    borderRadius: '3px',
    boxShadow: '0 0 10px 0 rgb(0 0 0 / 10%)',
    padding: '20px 0',
  },
} as const;

export default TableSection;
