import React from 'react';
import Link from 'next/link';

import BriefCase from 'js/icons/BriefCase';
import PaintBrush from 'js/icons/PaintBrush';
import Thumbnail from 'js/components/partial/Thumbnail/';
import { capitalizeAll } from 'js/utils';

import { type ProjectData } from 'js/api/projects';

import { DesignerBlock } from './styled';
import { type Columns, type ColumnData } from './types';

interface Props {
  project: ProjectData;
  columns: Columns;
}

export function ProjectRow({ project, columns }: Props) {
  return (
    <tr>
      {Object.entries(columns).map(([key, options]) => (
        <td key={key} style={styles.bodyCell}>
          {rendreCol(project, key as keyof ProjectData, options)}
        </td>
      ))}
    </tr>
  );
}

const rendreCol = (project: ProjectData, key: string, options: ColumnData) => {
  const { renderFn } = options;
  switch (key) {
    case 'projectId':
      return renderProjectIdCol(project);
    case 'name':
      return renderCustomerNameCol(project);
    case 'designer':
      return renderDesignerCol(project);
    case 'status':
      return renderStatusCol(project);
    default: {
      const value = (project as { [k: string]: any })[key];
      return (renderFn && renderFn(value)) || value;
    }
  }
};

/**
 * Renders the project ID column.
 *
 * This function displays the `oldEamesId` of the project if it exists.
 * If `oldEamesId` is not available, it falls back to displaying the regular `id` of the project.
 */
const renderProjectIdCol = (project: ProjectData) => {
  const projectId = project.oldEamesId ?? project.id;
  return (
    <div
      style={styles.projectId}
      title={project.oldEamesId ? 'Old eames project id' : undefined}
    >
      {projectId}
    </div>
  );
};

const renderStatusCol = (project: ProjectData) => {
  return project.status ? capitalizeAll(project.status) : null;
};

const renderDesignerCol = (project: ProjectData) => {
  const designersNames = project.designers
    ?.map(({ fullName }) => fullName)
    .join(' | ');
  const salespersonsNames = project.salespersons
    ?.map(({ fullName }) => fullName)
    .join(' | ');

  return (
    <DesignerBlock>
      {designersNames ? (
        <span>
          <div className="icon">
            {/* @TODO check condition */}
            <PaintBrush active={false} />
          </div>
          <p className="horizontal-sroll-styled" title={designersNames}>
            {designersNames}
          </p>
        </span>
      ) : null}
      {salespersonsNames ? (
        <span>
          <div className="icon">
            {/* @TODO check condition */}
            <BriefCase active={false} />
          </div>
          <p className="horizontal-sroll-styled" title={salespersonsNames}>
            {salespersonsNames}
          </p>
        </span>
      ) : null}
    </DesignerBlock>
  );
};

const renderCustomerNameCol = (project: ProjectData) => {
  return (
    <div>
      <Link
        className="project-img-name"
        href={`/eames/project/[id]/?id=${project.id}`}
        as={`/project/${project.id}/`}
      >
        <Thumbnail
          src={project.streetviewImage?.thumbnailUrl || ''}
          width="40px"
        />
        <div style={styles.projectName} className="project-name-wrapper">
          <span className="project-name">{project.name}</span>
        </div>
      </Link>
    </div>
  );
};

const styles = {
  projectName: {
    textAlign: 'start',
  },
  bodyCell: {
    boxSizing: 'border-box',
    padding: '12px 5px',
    textAlign: 'center',
  },
  projectId: {
    textAlign: 'center',
  },
} as const;
