/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { type Project } from 'js/api';
import { PROJECT_TYPES } from 'js/config';

interface Props {
  projectTypeFilter: Project['projectType'];
  onFilterByProjectType: (newProjectType: Project['projectType']) => unknown;
}

export function ProjectTypeFilters({
  projectTypeFilter,
  onFilterByProjectType,
}: Props) {
  return (
    <div className="project-type-filters">
      <div
        className={`filter ${!projectTypeFilter ? 'active' : ''}`}
        onClick={() => onFilterByProjectType(null)}
      >
        All
      </div>
      {PROJECT_TYPES.map((projectType) => (
        <div
          className={`filter ${
            projectTypeFilter === projectType ? 'active' : ''
          }`}
          key={projectType}
          onClick={() => onFilterByProjectType(projectType)}
        >
          {projectType}
        </div>
      ))}
    </div>
  );
}
