import styled from 'styled-components';

import Button from 'js/components/partial/Button';

export const MoreButtonStyled = styled(Button)`
  margin-top: 15px;
  background: #fff;
  border-color: #eee;
`;

export const DesignerBlock = styled.div`
  display: flex;
  flex-direction: column;

  span {
    display: flex;

    .icon {
      display: block;
      width: 20px !important;
      height: 20px !important;
      margin-right: 5px;
    }

    p {
      max-width: 120px;
      overflow-x: scroll;
      white-space: nowrap;
      margin-bottom: 0;
      padding-bottom: 7px;
      margin-bottom: 3px;
    }
  }
`;

export const SpinnerContainer = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon-spinner-circle {
    position: static !important;
  }
`;
