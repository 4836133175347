import {
  useRef,
  useState,
  useEffect,
  useCallback,
  type Dispatch,
  type SetStateAction,
} from 'react';

export interface WithDebouncedStateOptions {
  timeout?: number; // Timeout for the debounced state update
}

/**
 * Hook that provides state with a debounce update api.
 */
export function useDebouncedState<State>(
  initialState: State | (() => State),
  options: WithDebouncedStateOptions = {}
) {
  const { timeout = 300 } = options ?? {};

  const [state, _setStateInternal] = useState(initialState);
  const [debouncedState, _setDebouncedStateInternal] = useState(initialState);
  const timer = useRef<number>();

  useEffect(() => {
    return () => resetTimer();
  }, []);

  const setDebouncedState: Dispatch<SetStateAction<State>> = useCallback(
    (...args) => {
      resetTimer();

      _setStateInternal(...args);

      timer.current = window.setTimeout(() => {
        _setDebouncedStateInternal(...args);
      }, timeout);
    },
    []
  );

  const setState: Dispatch<SetStateAction<State>> = useCallback((...args) => {
    resetTimer();
    _setStateInternal(...args);
  }, []);

  const resetTimer = useCallback(() => {
    if (timer.current) window.clearTimeout(timer.current);
  }, []);

  return {
    state,
    debouncedState,
    setState,
    setDebouncedState,
  };
}
