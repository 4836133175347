import React from 'react';
import styled from 'styled-components';

import { toClassNames } from 'js/utils';

/**
 * ThumbnailWrapperStyled
 */

interface ThumbnailWrapperStyledProps {
  borderRadius: string;
  width: string;
}

const ThumbnailWrapperStyled = styled.span<ThumbnailWrapperStyledProps>`
  display: inline-block;
  position: relative;
  border-radius: ${(props) => props.borderRadius};
  line-height: 0;
  overflow: hidden;
  width: ${(props) => props.width};
`;

/**
 * ImgPlaceholder
 */
interface ImgPlaceholderProps {
  color: string;
  ratio: number;
}

function ImgPlaceholder({ color, ratio }: ImgPlaceholderProps) {
  return (
    <svg viewBox={`0 0 1 ${ratio}`}>
      <rect fill={color} width="1" height={ratio} />
    </svg>
  );
}

/**
 * ImgStyled
 */
const ImgStyled = styled.img`
  position: absolute;
  left: 0;
  top: 0;
`;

/**
 * Thumbnail (Main)
 */
interface ThumbnailProps extends React.HTMLAttributes<HTMLSpanElement> {
  src: string;
  alt?: string;
  className?: string;
  placeholderColor?: string;
  borderRadius?: string;
  ratio?: number;
  width?: string;
}

function Thumbnail({
  alt = 'thumbnail',
  className = '',
  placeholderColor = '#ddd',
  borderRadius = '3px',
  ratio = 1, // 0-1 (height to width)
  src,
  width = '100%',
  ...rest
}: ThumbnailProps) {
  return (
    <ThumbnailWrapperStyled
      className={toClassNames('thumbnail', className)}
      borderRadius={borderRadius}
      width={width}
      {...rest}
    >
      {src && <ImgStyled alt={alt} src={src} width={width} />}
      <ImgPlaceholder color={placeholderColor} ratio={ratio} />
    </ThumbnailWrapperStyled>
  );
}

export default Thumbnail;
