import React from 'react';

function Empty() {
  return (
    <svg viewBox="0 0 89 37" width="88">
      <defs>
        <path
          id="a"
          d="M61.456 17h23.986l-8.19-14.4C76.435 1.168 74.428 0 72.77 0h-58.66c-1.648 0-3.66 1.164-4.478 2.6L1.442 17h28.014v6c0 .553.444 1 .993 1h30.013c.54 0 .993-.447.993-1v-6z"
        />
        <path
          id="b"
          d="M32 14H2C.344 14-.493 15.242.134 16.775l5.973 14.6C7.153 33.93 10.235 36 12.993 36h61.014c2.75 0 5.84-2.07 6.885-4.625l5.973-14.6C87.49 15.245 86.657 14 85 14H56v2.998C56 19.76 53.764 22 50.998 22H37.002C34.24 22 32 19.77 32 16.998V14z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 88 0)">
        <rect width="82" height="2" x="3" y="35" fill="#ECECEC" rx="1" />
        <use fill="#ECECEC" xlinkHref="#a" />
        <path
          stroke="#686868"
          strokeWidth="2"
          d="M30.456 23h30.007-.007v-7h23.267l-7.34-12.905C75.742 1.975 74.067 1 72.772 1h-58.66c-1.293 0-2.972.974-3.61 2.095L3.163 16h27.293v7z"
        />
        <g>
          <use fill="#FFF" xlinkHref="#b" />
          <path
            stroke="#686868"
            strokeWidth="2"
            d="M31 15H2c-.945 0-1.297.523-.94 1.397l5.973 14.6C7.925 33.176 10.64 35 12.993 35h61.014c2.347 0 5.07-1.827 5.96-4.004l5.972-14.6c.356-.872.005-1.396-.94-1.396H57v1.998C57 20.314 54.315 23 50.998 23H37.002C33.684 23 31 20.32 31 16.998V15z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Empty;
