import { formatDate } from 'js/utils';
import { FILTER_STATUS } from 'js/api/projects';

import { type Tables } from './types';

// @TODO It not use anywhere
export const SEARCH_FIELDS = ['name', 'designersNames', 'salespersonsNames'];

export const TABLE: Tables<
  FILTER_STATUS,
  string /* @TODO: add type for exact keys that can be used in COLUMNS */
> = {
  // ADMIN
  [FILTER_STATUS.ALL]: {
    TITLE: 'Searched projects',
    COLUMNS: {
      projectId: { label: 'ID', width: '6%' },
      name: { label: 'NAME', sortable: true },
      status: { label: 'STATUS', width: '12%' },
      projectType: { label: 'TYPE', width: '9%' },
      reservationStartDate: {
        label: 'RESERVATION START',
        renderFn: formatDate,
        sortable: true,
        width: '12%',
      },
      installDate: {
        label: 'INSTALL START',
        renderFn: formatDate,
        sortable: true,
        width: '8%',
      },
      designer: { label: 'DESIGNER \n SALESPERSON', width: '15%' },
    },
  },

  [FILTER_STATUS.RESERVING]: {
    TITLE: 'Reserving & Upcoming Projects',
    COLUMNS: {
      projectId: { label: 'ID', width: '6%' },
      name: { label: 'NAME', sortable: true },
      status: { label: 'STATUS', width: '12%' },
      projectType: { label: 'TYPE', width: '9%' },
      reservationStartDate: {
        label: 'RESERVATION START',
        renderFn: formatDate,
        sortable: true,
        width: '12%',
      },
      installDate: {
        label: 'INSTALL START',
        renderFn: formatDate,
        sortable: true,
        width: '8%',
      },
      designer: { label: 'DESIGNER \n SALESPERSON', width: '15%' },
    },
  },

  [FILTER_STATUS.PICKING]: {
    TITLE: 'Projects Being Picked',
    COLUMNS: {
      projectId: { label: 'ID', width: '6%' },
      name: { label: 'NAME', sortable: true },
      status: { label: 'STATUS', width: '12%' },
      projectType: { label: 'TYPE', width: '9%' },
      reservationStartDate: {
        label: 'RESERVATION START',
        renderFn: formatDate,
        sortable: true,
        width: '12%',
      },
      installDate: {
        label: 'INSTALL START',
        renderFn: formatDate,
        sortable: true,
        width: '8%',
      },
      designer: { label: 'DESIGNER \n SALESPERSON', width: '15%' },
    },
  },

  [FILTER_STATUS.ON_SITE]: {
    TITLE: 'Projects On-Site',
    COLUMNS: {
      projectId: { label: 'ID', width: '6%' },
      name: { label: 'NAME', sortable: true },
      status: { label: 'STATUS', width: '12%' },
      projectType: { label: 'TYPE', width: '9%' },
      installDate: {
        label: 'INSTALL START',
        renderFn: formatDate,
        sortable: true,
        width: '8%',
      },
      endDate: {
        label: 'MOVE OUT',
        renderFn: formatDate,
        sortable: true,
        width: '9%',
      },
      designer: { label: 'DESIGNER \n SALESPERSON', width: '15%' },
    },
  },

  [FILTER_STATUS.ARCHIVED]: {
    TITLE: 'Archived Projects',
    COLUMNS: {
      projectId: { label: 'ID', width: '6%' },
      name: { label: 'NAME', sortable: true },
      status: { label: 'STATUS', width: '10%' },
      projectType: { label: 'TYPE', width: '9%' },
      installDate: {
        label: 'INSTALL START',
        renderFn: formatDate,
        sortable: true,
        width: '8%',
      },
      endDate: {
        label: 'MOVE OUT DATE',
        renderFn: formatDate,
        sortable: true,
        width: '10%',
      },
      designer: { label: 'DESIGNER \n SALESPERSON', width: '15%' },
    },
  },

  [FILTER_STATUS.LOST]: {
    TITLE: 'Closed Lost',
    COLUMNS: {
      projectId: { label: 'ID', width: '6%' },
      name: { label: 'NAME', sortable: true },
      status: { label: 'STATUS', width: '12%' },
      projectType: { label: 'TYPE', width: '9%' },
      reservationStartDate: {
        label: 'RESERVATION START',
        renderFn: formatDate,
        sortable: true,
        width: '12%',
      },
      installDate: {
        label: 'INSTALL START',
        renderFn: formatDate,
        sortable: true,
        width: '8%',
      },
      designer: { label: 'DESIGNER \n SALESPERSON', width: '16%' },
    },
  },

  // DESIGNER
  [FILTER_STATUS.ACTIVE]: {
    TITLE: 'Active Projects',
    COLUMNS: {
      projectId: { label: 'ID', width: '6%' },
      name: { label: 'NAME', sortable: true },
      status: { label: 'STATUS', width: '12%' },
      projectType: { label: 'TYPE', width: '10%' },
      reservationStartDate: {
        label: 'RESERVATION START',
        renderFn: formatDate,
        sortable: true,
        width: '15%',
      },
      installDate: {
        label: 'INSTALL START',
        renderFn: formatDate,
        sortable: true,
        width: '10%',
      },
    },
  },

  [FILTER_STATUS.COMPLETED]: {
    TITLE: 'Completed & Archived Projects',
    COLUMNS: {
      projectId: { label: 'ID', width: '6%' },
      name: { label: 'NAME', sortable: true },
      status: { label: 'STATUS', width: '12%' },
      projectType: { label: 'TYPE', width: '10%' },
      installDate: {
        label: 'INSTALL START',
        renderFn: formatDate,
        sortable: true,
        width: '10%',
      },
      designerCompletedAt: {
        label: 'INSTALL COMPLETION',
        renderFn: formatDate,
        sortable: true,
        width: '22%',
      },
    },
  },
};
