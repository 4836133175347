import React, {
  useCallback,
  useEffect,
  useState,
  type ChangeEventHandler,
} from 'react';
import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Link from 'next/link';

import Header from 'js/components/Header';
import NoProjects from 'js/components/projects/v2/NoProjects';
import IconSpinnerCircle from 'js/icons/SpinnerCircle';

import {
  FILTER_STATUS,
  useGetProjectsFacetsQuery,
  invalidateGetProjectsInfiniteQueryData,
  type Project,
  FILTERS,
} from 'js/api/projects';

import { useDebouncedState } from 'js/hooks';
import { getUserFromLocalStorage, isAdminUser } from 'js/utils';
import { track, TRACKED_EVENTS, TRACKED_PAGES } from 'js/utils/analytics';

import TableSection from './TableSection';
import { ProjectTypeFilters } from './ProjectTypeFilters';
import { ProjectStatusFilters } from './ProjectStatusFilters';
import {
  AddProjectButton,
  GridStyled,
  SearchInput,
  ProjectsFilterContainer,
  SearchContainer,
  SpinnerStatusWrapper,
} from './styled';

export default function Projects() {
  const {
    push,
    query: { status: statusParams },
  } = useRouter();
  const [status, setStatus] = useState<FILTER_STATUS>(FILTER_STATUS.ALL);
  const [projectType, setProjectType] = useState<Project['projectType']>(null);
  const queryClient = useQueryClient();

  const {
    state: keyword,
    debouncedState: debouncedKeyword,
    setState: setKeyword,
    setDebouncedState: setDebouncedKeyword,
  } = useDebouncedState('');

  const user = getUserFromLocalStorage();
  const isAdmin = isAdminUser(user);

  const filtersList = isAdmin ? FILTERS.ADMIN : FILTERS.DESIGNER;

  const getAllProjectsResult = useGetProjectsFacetsQuery({});

  /* ----- EFFECTS ----- */

  useEffect(() => {
    window.document.body.setAttribute('id', 'projects');
  }, []);

  useEffect(() => {
    setStatus(getStatusFilter());
  }, [statusParams]);

  useEffect(() => {
    setKeyword('');
  }, [status]);

  useEffect(() => {
    return () => {
      // Invalidate cache for projects sections here,
      // so that a user will see fresh sections without prefetched pages
      invalidateGetProjectsInfiniteQueryData(queryClient, []).catch(
        console.error
      );
    };
  }, []);

  // Analytics

  useEffect(() => {
    track(TRACKED_PAGES.PROJECT_LIST);
  }, []);

  useEffect(() => {
    track(
      isAdminUser(user)
        ? TRACKED_EVENTS.PROJECTS_FILTERED_BY_ADMIN
        : TRACKED_EVENTS.PROJECTS_FILTERED_BY_DESIGNER,
      { filter: status }
    );
  }, [user, status]);

  /* ----- Helpers ----- */

  const getStatusFilter = (): FILTER_STATUS => {
    return statusParams && filtersList.includes(statusParams as FILTER_STATUS)
      ? (statusParams as FILTER_STATUS)
      : filtersList[0];
  };

  const getTableSectionsByStatus = (status: FILTER_STATUS) => {
    switch (status) {
      case FILTER_STATUS.ALL:
        return filtersList.filter((f) => f !== FILTER_STATUS.ALL);
      default:
        return [status];
    }
  };

  /* ----- HANDLERS ----- */

  const handleSearchInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newKeyword = e.target.value;
      setDebouncedKeyword(newKeyword);
    },
    []
  );

  const handleFilterByProjectType = useCallback(
    (newProjectType: Project['projectType']) => {
      setProjectType(newProjectType);
    },
    []
  );

  return (
    <article>
      <Head>
        <title>Vesta - Projects List</title>
      </Head>
      <Header />
      <GridStyled>
        <ProjectsFilterContainer>
          <div className="status-filters-row">
            <ProjectStatusFilters
              facets={getAllProjectsResult.data ?? {}}
              activeFilter={status}
              statusFiltersList={filtersList}
            />
            {getAllProjectsResult.isLoading && (
              <SpinnerStatusWrapper>
                <IconSpinnerCircle />
              </SpinnerStatusWrapper>
            )}
          </div>
          <ProjectTypeFilters
            projectTypeFilter={projectType}
            onFilterByProjectType={handleFilterByProjectType}
          />
          <SearchContainer>
            <SearchInput
              name="keyword"
              onChange={handleSearchInput}
              value={keyword}
            />
            {isAdmin && (
              <AddProjectButton
                cta
                onClick={() => push('/eames/project/new/', '/project/new/')}
              >
                Add a New Project
              </AddProjectButton>
            )}
          </SearchContainer>
        </ProjectsFilterContainer>
      </GridStyled>
      <GridStyled>
        {!getAllProjectsResult.data?.all && !getAllProjectsResult.isLoading && (
          <NoProjects
            subTitle={
              isAdmin ? (
                <Link href="/eames/project/new/" as="/project/new/">
                  Create a New Project
                </Link>
              ) : (
                ''
              )
            }
          />
        )}
        {getTableSectionsByStatus(status).map((status) => (
          <TableSection
            key={status}
            status={status}
            projectType={projectType}
            keyword={debouncedKeyword}
          />
        ))}
      </GridStyled>
    </article>
  );
}
